import React from 'react';
import ReactDOM from 'react-dom/client';
import MainPage from './pages/main';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CssBaseline>
    <BrowserRouter>
      <Routes>
        <Route path='/' Component={MainPage} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  </CssBaseline>
);