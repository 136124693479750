import React from "react";
import MuiAppBar from '@mui/material/AppBar';
import Grid from "@mui/material/Grid"
import Cisp1Logo from '../../../components/icons/cisp1-logo'
import './app-bar.scss'

export default function AppBar() {
    return (
        <MuiAppBar position="static" className="app-bar">
            <Grid container justifyContent="center" alignItems="center" gap={4}>
                <Cisp1Logo className="cisp1-logo" />
            </Grid>
        </MuiAppBar >
    );
}
