import React, { useState, useEffect, useRef } from 'react'
import { socket } from '../../utils/socket';
import Grid from '@mui/material/Grid'
import Textarea from '@mui/joy/Textarea';
import SendIcon from '@mui/icons-material/Send';
import IconButton from '@mui/material/IconButton'
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import PauseIcon from '@mui/icons-material/Pause';

import './question-bar.scss'

export default ({ context, setContext, suggestQuestion, suggestionId, setSuggestionId }) => {
    const [question, setQuestion] = useState(suggestQuestion)
    const [isRecording, setIsRecording] = useState(false)
    const [audioContext, setAudioContext] = useState(new AudioContext())
    const [sampleRate, setSampleRate] = useState()
    const [localStream, setLocalStream] = useState()
    const [rivaRunning, setRivaRunning] = useState(false)
    const questionInputRef = useRef(null)

    useEffect(() => {
        setQuestion(suggestQuestion)
    }, [suggestQuestion])

    const sendQuestion = () => {
        const newContext = [...context.filter(item => item.id !== suggestionId), { message_type: 'text', user_type: 'client', message: question, id: context.length }]

        setIsRecording(false)
        stopRivaService()
        setContext(newContext)
        setQuestion('')
        setSuggestionId(null)
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && event.target.value) {
            event.preventDefault()
            sendQuestion()
        }
    }

    const handleRecordClick = () => {
        questionInputRef.current.focus()
        setIsRecording(true)
        startRivaService()
    }

    const handlePauseButton = () => {
        setIsRecording(false)
        stopRivaService()
    }

    function SendButton() {
        return (
            <IconButton className="__button --blue-background" onClick={sendQuestion}>
                <SendIcon className="send-icon" />
            </IconButton>
        )
    }

    function RecordButton() {
        return (
            <IconButton className="__button --blue-background">
                <KeyboardVoiceIcon className="record-icon" onClick={handleRecordClick} />
            </IconButton>
        )
    }

    function PauseButton() {
        return (
            <IconButton className="__button --red-background">
                <PauseIcon className="pause-icon" onClick={handlePauseButton} />
            </IconButton>
        )
    }

    function requestLocalAudio() {
        navigator.mediaDevices.getUserMedia({
            audio: {
                noiseSuppression: true,
                echoCancellation: true
            }, video: false
        })
            .then((stream) => {
                setLocalStream(stream);
                setSampleRate(audioContext.sampleRate);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    const stopRivaService = () => {
        socket.disconnect();
        audioContext.close();
        setAudioContext(new AudioContext());

        setRivaRunning(false);
    }

    const startRivaService = () => {
        if (!socket.connected) {
            socket.connect();
        }

        audioContext.resume();

        if (rivaRunning) return;

        let audioInput = audioContext.createMediaStreamSource(localStream);
        let bufferSize = 4096;
        let recorder = audioContext.createScriptProcessor(bufferSize, 1, 1);
        let worker = new Worker('/resampler.js');

        worker.postMessage({
            command: 'init',
            config: {
                sampleRate: sampleRate,
                outputSampleRate: 16000
            }
        });

        // Use a worker thread to resample the audio, then send to server
        recorder.onaudioprocess = function (audioProcessingEvent) {
            let inputBuffer = audioProcessingEvent.inputBuffer;
            worker.postMessage({
                command: 'convert',
                buffer: inputBuffer.getChannelData(0)
            });
            worker.onmessage = function (msg) {
                if (msg.data.command === 'newBuffer') {
                    socket.emit('audio_in', msg.data.resampled.buffer);
                }
            };
        };

        audioInput.connect(recorder);
        recorder.connect(audioContext.destination);
        setRivaRunning(true);
    }

    useEffect(() => {
        requestLocalAudio()

        socket.on('transcript', function (result) {
            const transcript = result.transcript.trim()
            if (transcript === undefined ||
                transcript.toLowerCase() === 'sonant') return;

            if (result.is_final) {
                setQuestion((prevTranscription) => {
                    const updatedTranscription = prevTranscription + " " + transcript;

                    return updatedTranscription;
                });
            }
        });
    }, [socket]);

    return (
        <Grid container item className="question-bar" gap={4} justifyContent="space-between" alignItems="center">
            {isRecording ? <PauseButton /> : <RecordButton />}
            <Textarea variant="plain" className="__input" placeholder="Digite sua resposta aqui" value={question} onChange={({ target }) => setQuestion(target.value)} onKeyPress={handleKeyPress} slotProps={{ textarea: { ref: questionInputRef } }} />
            <SendButton />
        </Grid>
    )
}