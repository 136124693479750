import React, { useEffect, useRef, useState, useMemo } from 'react'
import './main-page.scss'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import MainLayout from '../../layouts/main'
import QuestionBar from '../../components/question-bar'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop'
import { getRetriever, getFakeClientResponse } from '../../utils/apiRoutes'

export default () => {
    const [context, setContext] = useState([])
    const [systemMessages, setSystemMessages] = useState([])
    const [isMessageSectionScrolledDown, setIsMessageSectionScrolledDown] = useState(false)
    const messagesSectionRef = useRef(null)
    const [messageCounter, setMessageCounter] = useState(0)
    const [suggestQuestion, setSuggestQuestion] = useState('')
    const [isMessageSuggested, setIsMessageSuggested] = useState(false);
    const [suggestionId, setSuggestionId] = useState(null)
    const [modalOpen, setModalOpen] = useState(true);
    const [chatContext, setChatContext] = useState(null)

    const ClientMessage = (id, message) => {
        return (
            <Grid key={id} style={{ alignSelf: 'flex-end', padding: '10px 20px', border: '2px solid #e9e9e9', borderRadius: '20px', backgroundColor: '#f5f5f5' }}>
                <p style={{ margin: 0 }}>Atendente: {message}</p>
            </Grid>
        )
    }

    const SystemMessage = (id, message) => {
        return (
            <Grid key={id} style={{ alignSelf: 'flex-start', padding: '0px 20px', border: '2px solid #e9e9e9', borderRadius: '20px', backgroundColor: '#ffffff' }}>
                <p>Cliente: {message}</p>
            </Grid>
        )
    }

    const SuggestionMessage = (id, message) => {
        return (
            <Grid key={id} style={{ alignSelf: 'flex-start', padding: '0px 20px', border: '2px solid #e9e9e9', backgroundColor: '#fff8aa' }}>
                <p>Sugestão de resposta:</p>
                <p>{message}</p>
                <div style={{ paddingBottom: '1%' }}>
                    <Stack spacing={2} direction="row">
                        <Button onClick={() => copySuggestionMessage(id, message)}>Copiar</Button>
                        <Button onClick={() => answerWithSuggestionMessage(id, message)}>Responder</Button>
                    </Stack>
                </div>
            </Grid>            
        )
    }

    const removeSuggestionMessage = (id) => {
        setContext(prevItems => prevItems.filter(item => item.id !== id));
    }

    const copySuggestionMessage = (id, message) => {
        setIsMessageSuggested(true);
        removeSuggestionMessage(id);
        setSuggestQuestion(message);
    }

    const answerWithSuggestionMessage = (id, message) => {
        setIsMessageSuggested(true);
        removeSuggestionMessage(id);
        const newContext = [...context.filter(item => item.id !== id), { message_type: 'text', user_type: 'client', message: message, id: id }]
        setContext(newContext)
    }

    const renderTextMessage = (id, user_type, message) => {
        if (user_type === 'client')
            return ClientMessage(id, message)
        
        if (user_type === 'system')
            return SystemMessage(id, message)

        if (user_type === 'suggestion'){
            return SuggestionMessage(id, message)
        }
    }

    const renderGraphic = (graphic_type, data) => {
        return (
            <ResponsiveContainer width="100%" aspect={2}>
                <LineChart
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
                    <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
                </LineChart>
            </ResponsiveContainer>
        )
    }

    const scrollDownMessages = () => {
        messagesSectionRef.current.scrollTo({
            top: messagesSectionRef.current.scrollHeight,
            behavior: 'smooth'
        })
        setIsMessageSectionScrolledDown(true)
    }

    const onScroll = ({ target }) => {
        if ((target.scrollHeight - target.scrollTop) === target.clientHeight) {
            setIsMessageSectionScrolledDown(false)
        } else if (isMessageSectionScrolledDown) {
            setIsMessageSectionScrolledDown(true)
        }
    }

    useEffect(() => {
        fetch('system-messages.json').then(data => data.json())
        .then(result => setSystemMessages(result.messages))
        .catch(err => console.log("err => ", err))
    }, [])

    useEffect(() => {
        if (chatContext) {
            setTimeout(() => {
                if (systemMessages.length){
                    const newContext = [...context, { message_type: 'text', user_type: 'system', message: systemMessages[0], id: 0 }]
                    setContext(newContext)
                    // setMessageCounter(messageCounter + 1)
                }
            }, 1000)
        }
    }, [systemMessages, chatContext])

    useEffect(() => {
        messagesSectionRef.current.onscroll = onScroll
    }, [isMessageSectionScrolledDown])

    useEffect(() => {
        const lastMessage = context[context.length - 1]

        if (context.length) {
            if (lastMessage.user_type === 'client') {
                getFakeClientResponse({'context': context, 'chatContext': chatContext}).then(({data}) => {
                    if (data.response != ''){
                        const newContext = [...context, { message_type: 'text', user_type: 'system', message: data.response, id: context.length }]
                        setContext(newContext)
                    }
                })
            } else if (lastMessage.user_type === 'system' && !isMessageSuggested && lastMessage.message != '') {
                getRetriever({'sentence': lastMessage.message, 'context': context}).then(({data}) => {
                    const newContext = [...context, { message_type: 'text', user_type: 'suggestion', message: data.response, id: context.length }]
                    setContext(newContext)
                    setSuggestionId(context.length)
                })
            }
        }

        scrollDownMessages()
        setIsMessageSuggested(false)
        // console.log("context", context)
    }, [context])

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        outline: 'none'
    };
      
    const buttonContainerStyle = {
        display: 'flex',
        flexDirection: 'row',
        gap: '16px',
        marginTop: '16px'
    };

    const handleModalOption = (option) => {
        setChatContext(option);
        setModalOpen(false);
    }

    return (
        <MainLayout>
            <Modal
                open={modalOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropComponent={Backdrop}
                BackdropProps={{
                    onClick: (event) => event.stopPropagation(),
                    timeout: 500,
                }}
                disableEscapeKeyDown
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                    Qual SAC deseja ser atendido?
                    </Typography>
                    <Box sx={buttonContainerStyle}>
                        <Button onClick={() => {handleModalOption('support')}}>Suporte</Button>
                        <Button onClick={() => {handleModalOption('selling')}}>Vendas</Button>
                    </Box>
                </Box>
            </Modal>
            <Grid container item direction="column" wrap="nowrap" xs={8} style={{ height: "100%", padding: "100px 0 30px 0" }}>
                <Box style={{ display: 'flex', flexGrow: 1, flexShrink: 1 }} />
                <Grid container direction="column" wrap="nowrap" className="messages-section" gap={4} ref={messagesSectionRef} style={{
                    height: 'auto',
                    overflowY: 'auto',
                    scrollbarWidth: 'none'
                }}>
                    {
                        context.map(({ message, user_type, message_type, graphic_type, id, data }) => {
                            return message_type === 'text' ?
                                renderTextMessage(id, user_type, message)
                                : renderGraphic(graphic_type, data)
                        })
                    }
                </Grid>
                {!isMessageSectionScrolledDown ?
                    <Button variant="outlined" style={{
                        backgroundColor: "#fff",
                        position: "fixed",
                        bottom: "100px",
                        width: "fit - content",
                        alignSelf: "center",
                        borderRadius: "25px"
                    }} onClick={scrollDownMessages}>Ir para o fim</Button>
                    : null}
                <QuestionBar context={context} setContext={setContext} suggestQuestion={suggestQuestion} suggestionId={suggestionId} setSuggestionId={setSuggestionId} />
            </Grid>
        </MainLayout >
    )
}